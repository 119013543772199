<template>
  <div class="page1">
    <EForm
      :formColumns="formColumns"
      :rowSize="4"
      :optionsBtn="true"
      :actionBtn="false"
      :formData="searchForm"
      ref="form"
      @onSearch="onSearch"
      :searchFlag="true"
      @getChange="changeSelect"
      url="/cm-order/cmorder/unCostExport"
    >
    </EForm>
    <div class="mt20 fs15">符合条件记录：共{{ total.feeNum }}单，{{ total.totalAmount }}元</div>
    <ETable
      :tableTitle="tableTitle"
      :tableData="tableData"
      :needPagination="true"
      :count="count"
      @changePage="changePage"
      :needIndex="false"
      :page="searchForm.current"
      :page_size="searchForm.size"
    >
      <!-- <el-table-column fixed="right" label="操作" width="80px">
        <template slot-scope="scope">
          <el-button type="text" @click="toDetail(scope.row)">明细</el-button>
        </template>
      </el-table-column> -->
    </ETable>
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import { identity_type, status } from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';

  export default {
    name: 'unChargeRefundList',
    data() {
      return {
        tableTitle: [
          {
            label: '序号',
            prop: 'index',
          },
          {
            label: '区域',
            prop: 'areaName',
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode',
          },
          {
            label: '摊位面积',
            prop: 'boothCode',
          },
          {
            label: '摊位类型',
            prop: 'boothCode',
          },
          {
            label: '商户名称',
            prop: 'tenantName',
          },
          {
            label: '收费项目名称',
            prop: 'tenantName',
          },
          {
            label: '收/退',
            prop: 'feeNum',
          },
          {
            label: '金额',
            prop: 'chargePersonName',
          },
          {
            label: '费用起始日期',
            prop: 'payTime',
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate',
          },
          {
            label: '录入人',
            prop: 'confirmPersonName',
          },
          {
            label: '录入时间',
            prop: 'transferAmount',
          },
          {
            label: '财务确认人',
            prop: 'cashAmount',
          },
          {
            label: '财务确认时间',
            prop: 'cashAmount',
          },
          {
            label: '备注',
            prop: 'remark',
          },
        ],
        formColumns: [
          {
            title: '摊位编号',
            type: 'text',
            property: 'boothCode',
            show: true,
          },
          {
            title: '录入人',
            type: 'text',
            property: 'boothCode',
            show: true,
          },
          {
            title: '费用起始时间',
            type: 'datePicker',
            property: 'payTimeStart',
            show: true,
          },
          {
            title: '费用截止时间',
            type: 'datePicker',
            property: 'payTimeEnd',
            show: true,
          },
          {
            title: '区域',
            type: 'select',
            property: 'areaId',
            show: true,
            options: [],
          },
          {
            title: '财务确认人',
            type: 'select',
            property: 'areaId',
            show: true,
            options: [],
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options: [],
          },
          {
            title: '收费项目',
            type: 'text',
            property: 'chargePersonName',
            show: true,
          },
        ],
        tableData: [],
        searchForm: {
          boothCode: null,
          payTimeStart: null,
          payTimeEnd: null,
          areaId: null,
          placeId: null,
          chargePersonName: null,
          current: 1,
          size: 10,
        },
        count: null,
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        formRules: {
          boothCode: vxRule(true, '', 'blur', '摊位编号不能为空'),
          areas: vxRule(true, '', 'blur', '面积不能为空'),
          areaId: vxRule(true, '', 'change', '区域不能为空'),
          boothUseId: vxRule(true, '', 'change', '摊位用途不能为空'),
          placeId: vxRule(true, '', 'change', '位置不能为空'),
          boothTypeId: vxRule(true, '', 'change', '摊位类型不能为空'),
          remark: vxRule(true, '', 'blur', '说明不能为空'),
        },
        total: {
          feeNum: 0,
          totalAmount: 0,
        },
        dialogForm: {
          boothInformationId: '',
          boothCode: '',
          areas: '',
          areaId: '',
          boothUseId: '',
          placeId: '',
          boothTypeId: '',
          remark: '',
        },
        options: [],
        form: {},
        areaOptions: [],
        locationOptions: [],
        boothUseOptions: [],
        boothTypeOptions: [],
        tableTitle2: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName',
          },
          {
            label: '年',
            prop: 'year',
          },
          {
            label: '月',
            prop: 'month',
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate',
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate',
          },
          {
            label: '收费金额',
            prop: 'payAmount',
          },
          {
            label: '第几期',
            prop: 'periods',
          },
          {
            label: '是否到账',
            prop: 'isPayCn',
          },
          {
            label: '是否打印交费单',
            prop: 'isPrintCn',
          },
          {
            label: '收/退',
            prop: 'dealTypeCn',
          },
          {
            label: '退费金额',
            prop: 'returnAmount',
          },
          {
            label: '备注',
            prop: 'remark',
          },
        ],
        tableData2: [],
      };
    },
    watch: {},
    created() {
      this.getData();
      this.getAreaLabel();
    },
    components: { ETable, EDialog, EButton, EForm },
    beforeMount() {},
    methods: {
      // 导出所需数据
      exportDataFn() {
        let { boothCode, chargePersonName, payTime, areaId, placeId, current, size } = this.searchForm;
        let exportData = {
          boothCode,
          chargePersonName,
          payTimeStart: payTime ? payTime[0] : '',
          payTimeEnd: payTime ? payTime[1] : '',
          areaId,
          placeId,
          current,
          size,
        };
        return exportData;
      },
      // 修改select
      changeSelect(prop) {
        if (prop == 'areaId') {
          this.getPlaceLabel();
        }
      },
      dialogShow(items) {
        this.dialogVisible = true;
        this.tableData2 = items;
      },
      onSearch() {
        this.searchForm.current = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.current = current;
        this.getData();
      },
      cancelDialog() {
        this.dialogVisible = false;
      },
      showDialog(row) {
        console.log(row);
        this.dialogForm = {
          boothInformationId: row.boothInformationId,
          boothCode: row.boothCode,
          areas: row.areas,
          areaId: row.areaId,
          boothUseId: row.boothUseId,
          placeId: row.placeId,
          boothTypeId: row.boothTypeId,
          remark: row.remark,
        };
        this.dialogVisible = true;
      },
      async getData() {
        let res = await Http.getOrderList(this.searchForm);
        if (res.code == 200) {
          this.tableData = res.data.page.records;
          this.count = res.data.page.total;
          this.total = {
            feeNum: res.data.feeNum,
            totalAmount: res.data.totalAmount,
          };
        }
      },
      //获取位置下拉
      async getPlaceLabel() {
        let res = await Http.getPlaceLabel({
          areaId: this.searchForm.areaId,
        });
        if (res.code == 200) {
          this.formColumns[4].options = res.data;
          this.locationOptions = res.data;
        }
      },
      //获取区域下拉
      async getAreaLabel() {
        let res = await Http.getAreaLabel({ status: null });
        if (res.code == 200) {
          this.formColumns[3].options = res.data;
        }
      },
      async getListByOrderId(id) {
        let res = await Http.getListByOrderId({ id });
        if (res.code == 200) {
          this.tableData2 = res.data;
          this.dialogVisible = true;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.ws {
  width: 100%;
}
</style>
